import { FC } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { ChartColors } from '@/config/Charts';

const data = [{ name: '01/08', uv: 400, pv: 5400, amt: 2400 }, { name: '02/08', uv: 300, pv: 2400, amt: 2400 }, { name: '03/08', uv: 350, pv: 2400, amt: 2400 }, { name: '04/08', uv: 450, pv: 2400, amt: 2400 }, { name: '05/08', uv: 100, pv: 2400, amt: 2400 }];

interface LineChartProps {
    data: any[];
    lines: string[];
}


const LChart: FC<LineChartProps> = ({ data, lines }) => {
    return (
        <ResponsiveContainer height={300} width="100%">
            <LineChart width={600} height={300} data={data}>
                <CartesianGrid stroke="#666" />
                {lines.map((line: string, idx: number) => {
                    return (<Line key={idx} type="monotone" dataKey={line} stroke={ChartColors[idx]} />)
                })}
                <XAxis dataKey="day" />
                <YAxis />
            </LineChart>
        </ResponsiveContainer>
    );
}

export default LChart;
