import { FC, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Action, ActionTrigger } from "@/interfaces";
import CdpClient from "@/client";
import { IconActionEmail, IconActionWebhook, IconClose, IconPencil, IconPlus } from "@/components/Icons";
import { ActionForm } from "@/forms/ActionForm";

interface ActionPageProps {
    client: CdpClient
}

export const ActionsPage: FC<ActionPageProps> = ({ client }) => {
    const [actions, setActions] = useState<Action[]>([]);
    const [triggers, setTriggers] = useState<ActionTrigger[]>([]);
    const [selectedAction, setSelectedAction] = useState<number>(-1);
    const [modalActive, setModalActive] = useState<boolean>(false);
    const [actionType, setActionType] = useState<string>('');
    const [triggerType, setTriggerType] = useState<string>('');
    const [triggerTypeId, setTriggerTypeId] = useState<string>('');
    const once = useRef(false);
    const [actionSpecs, setActionSpecs] = useState<any>([]);
    const [triggerSpecs, setTriggerSpecs] = useState<any>([]);
    const [triggerSpecsById, setTriggerSpecsById] = useState<any>({});

    useEffect(() => {
        if (!once.current) {
            client.getActions((response: any) => {
                setActions(response);
            });
            // client.getTriggers().then((response: any) => {
            //     setTriggers(response.data);
            // });
            once.current = true;
        }
    }, [client]);

    return (<>
        <h1 className="title">Actions <Link to='/admin/actions/new'><IconPlus /></Link></h1>
        <p>Actions are integration events that can triggered on segment activity and incoming events</p>
        <div className="box">
            {actions.map((action: Action, idx: number) => {
                return (<div key={idx} className="box">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                {action.action_type === "webhook" ? <IconActionWebhook /> : <IconActionEmail />}
                            </div>
                            <div className="level-item">
                                <h3 className="subtitle">
                                    {action.description}
                                </h3>
                            </div>
                            <div className="level-item">
                                <span className="tag">{action.action_type}</span>
                            </div>
                        </div>
                        <Link to={`/admin/actions/${action.aid}`}><button className="button has-text-primary"> <IconPencil /></button></Link>
                    </div>
                    {(action.triggers && parseInt(action.triggers?.length + '') > 0) && action.triggers.map((trigger: ActionTrigger, idx: number) => {
                            if (!trigger) return null;

                            return (<div key={idx}>
                                <h4 className="subtitle is-6">{trigger.trigger_type} | {trigger.conditions?.type} {trigger.trigger_type_id}</h4>
                            </div>)
                        })}
                </div>)
            })
            }
        </div>
    </>)
}