import { FC, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { SegmentSpec, Stat } from '@/interfaces';
import LineChart from '@/components/LineChart';
import BarChart from '@/components/BarChart';
import CdpClient from '@/client';
import { types } from 'util';
import { IconPencil } from '@/components/Icons';

interface SegmentDetailPageProps {
    cdpClient: CdpClient;
}

const SegmentDetailPage: FC<SegmentDetailPageProps> = ({ cdpClient }) => {
    const { sid } = useParams<{ sid: string }>();
    const once = useRef(false);
    const [segment, setSegment] = useState<SegmentSpec>();
    const [segmentStats, setSegmentStats] = useState<any>({});
    const [segmentData, setSegmentData] = useState<any>({});
    const [customerTotal, setCustomerTotal] = useState<number>(0);
    const [segmentTotal, setSegmentTotal] = useState<number>(0);
    useEffect(() => {
        if (once.current) {
            return;
        }
        cdpClient.getSegments((response: any) => {
            for (let i = 0; i < response.length; i++) {
                if (response[i].id !== parseInt(sid + '')) continue;
                setSegment(response[i]);
                break;
            }
        });
        cdpClient.getStats(`segment-day/${sid}`, {}, (response: Stat) => {
            const stats = {
                types: response.types,
                stats: response.days.map((entry: any) => {
                    return {
                        name: entry.day,
                        ...entry.stats,
                    };
                }),
            }
            console.log('stat', response, stats);
            setSegmentStats(stats || []);
        });
        cdpClient.getStats(`segment/${sid}`, {}, (response: Stat) => {
            const entry = response.days[0];
            const data = {
                stats: [{
                    name: entry.day,

                    total: entry.total,
                    ...entry.stats,
                }],
                bars: response.types,
            };
            setSegmentTotal(entry.total);
            setSegmentData(data);
        });
        cdpClient.getStats(`customer-total`, {}, (response: Stat) => {
            console.log('customer total', response);
            const totalCustomers = Object.values(response)[0][0];
            setCustomerTotal(totalCustomers);
        });

        once.current = true;
    });
    if (!segment) {
        return <></>;
    }
    return (

        <div>
            <h1 className='title'>Segment Detail: <strong>{segment.title}</strong></h1>
            <div className='grid'>
                <div className='cell'>
                    <div className='box has-background-grey-dark'>
                    <LineChart
                        data={segmentStats?.stats || [{}]}
                        lines={segmentStats?.types || []}
                    />
                    </div>
                </div>
                <div className='cell'>
                    <div className='box has-background-grey-dark'>
                    <BarChart
                        data={segmentData?.stats || []}
                        bars={segmentData?.bars || []}
                    />
                    </div>
                </div>
                <div className='cell'>
                    <div className='box has-background-grey-dark'>
                        <p>{segment.title} contains</p>
                        <h1 className='title'>{customerTotal && (<>{Math.round(100*segmentTotal/customerTotal)}% of all <br /> customers</>) }</h1>
                    </div>
                </div>
            </div>
            <div className='grid'>
                <div className='cell'>
                    <div className='box'>
                        <h2 className='subtitle'>Description</h2>
                        <p>{segment.description}</p>
                    </div>
                </div>
                <div className='cell'>
                    <div className='box'>
                        <h2 className='subtitle'>Webhooks enter</h2>
                        
                        {segment.webhooks?.map((webhook, idx) => {
                            return <p key={idx}>{webhook.url} </p>;
                        })}
                    </div>
                </div>
                <div className='cell'>
                    <div className='box'>
                        <h2 className='subtitle'>Webhooks leave</h2>
                        {segment.webhooks_leave?.map((webhook, idx) => {
                            return <p key={idx}>{webhook.url} </p>;
                        })}
                    </div>
                </div>
            </div>
            <div className='grid'>
                <div className='cell'>
                    <div className='buttons'>
                        <Link to={`/segment-specs/${segment.id}`}><button className='button has-text-primary'>Edit &nbsp;<IconPencil /></button></Link>
                    </div>
                </div>
            </div>
        </div>
    );
};


export { SegmentDetailPage };
