import { FC, useState } from 'react';
import { Webhook } from '@/interfaces';
import { Button } from '@/components/Button';
import { IconClose } from '@/components/Icons';

interface WebhookFormProps {
    webhook: Webhook;
    onSave: (webhook: Webhook) => void;
}

export const WebhookForm: FC<WebhookFormProps> = ({ webhook, onSave }) => {
    const [webhookState, setWebhook] = useState(webhook);
    const [headers, setHeaders] = useState(webhook.headers || {});
    return (
        <div>
            <div className="field">
                <label className="label">Method</label>
                <div className="select">
                    <select className="select" defaultValue={webhookState.method} onChange={(evt) => {
                        setWebhook({
                            ...webhook,
                            method: evt.target.value
                        });
                        onSave({
                            ...webhook,
                            method: evt.target.value
                        });
                    }}>
                        <option value="GET">GET</option>
                        <option value="POST">POST</option>
                        <option value="PUT">PUT</option>
                        <option value="PATCH">PATCH</option>
                        <option value="DELETE">DELETE</option>
                    </select>
                </div>
            </div>
            <div className="field">
                <label className="label">URL</label>
                <div className="control">
                    <input className="input" type="text" defaultValue={webhookState.url} onChange={(evt) => {
                        onSave({
                            ...webhook,
                            url: evt.target.value
                        });
                    }} />
                </div>
            </div>
            <div className="field">
                <label className="label">Body (leave empty to send standard payload)</label>
                <div className="control">
                    <textarea className="textarea" defaultValue={webhookState.body} onChange={(evt) => {
                        const updatedWebhook = {
                            ...webhook,
                            body: evt.target.value
                        };
                        onSave(updatedWebhook);
                        setWebhook(updatedWebhook);
                    }} />
                    <button className='is-small has-text-danger' onClick={() => {
                        const updatedWebhook = {
                            ...webhook,
                            body: ''
                        };
                        onSave(updatedWebhook);
                        setWebhook(updatedWebhook);}}><IconClose /> Clear body </button>
                </div>
            </div>
            <div className="field">
                <label className="label">Headers</label>
                    {Object.keys(headers).map((header, idx) => {
                        return (
                            <div className="columns">
               
                                <div className="column">
                                    <input className="input" type="text" defaultValue={header} onChange={(evt) => {
                                        const newHeaders = { ...headers };
                                        delete newHeaders[header];
                                        newHeaders[evt.target.value] = headers[header] + '';
                                        setHeaders(newHeaders);
                                        onSave({
                                            ...webhook,
                                            headers: newHeaders
                                        });
                                    }} />
                                </div>
                                <div className="column">
                                    <input className="input" type="text" defaultValue={headers[header]} onChange={(evt) => {
                                        const newHeaders = { ...headers };
                                        newHeaders[header] = evt.target.value;
                                        setHeaders(newHeaders);
                                        setWebhook({
                                            ...webhook,
                                            headers: newHeaders
                                        });
                                        onSave({
                                            ...webhook,
                                            headers: newHeaders
                                        });
                                    }} />
                                </div>
                            </div>
                        )
                    })}
                <Button text="+ Add header" onClick={() => {
                    setHeaders({ ...headers, '': '' });
                }} />
            </div>
        </div>
    );
};
