import { FC, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Account } from '@/interfaces';
import { IconPlus, IconSave } from '@/components/Icons';
import { warn } from 'console';

interface AccountFormProps {
    account: Account;
    onSave: (account: Account) => void;
}

const AccountContactForm: FC<AccountFormProps> = ({ account, onSave }) => {
    const [acc, setAccount] = useState<Account>(account);
    return (
        <div>
            <div className='field'>
                <label className='label'>Account Name</label>
                <input
                    className='input'
                    type='text'
                    value={acc.account_name}
                    onChange={(e) => setAccount({ ...acc, account_name: e.target.value } as Account)}
                />
            </div>
            <div className='field'>
                <label className='label'>Contact Email</label>
                <input
                    className='input'
                    type='text'
                    value={acc.email}
                    onChange={(e) => setAccount({ ...acc, email: e.target.value } as Account)}
                />
            </div>
            <div className='field'>
                <label className='label'>Contact Phone</label>
                <input
                    className='input'
                    type='text'
                    value={acc.phone}
                    onChange={(e) => { setAccount({ ...acc, phone: e.target.value } as Account) }}
                />
            </div>
            <button
                className='button is-primary'
                onClick={() => {
                    onSave(acc);
                }}
            ><IconSave />&nbsp; Save</button>
        </div>
    );
}

const AccountLimitForm: FC<AccountFormProps> = ({ account, onSave }) => {
    const [acc, setAccount] = useState<Account>(account);
    return (
        <div>
            <div className='field'>
                <label className='label'>Event Limit per Day</label>
                <input
                    className='input'
                    type='number'
                    value={acc.event_limit_day}
                    onChange={(e) => setAccount({ ...acc, event_limit_day: parseInt(e.target.value) } as Account)}
                />
            </div>
            <div className='field'>
                <label className='label'>Event Hard Limit</label>
                <input
                    className='input'
                    type='number'
                    value={acc.event_hard_limit}
                    onChange={(e) => setAccount({ ...acc, event_hard_limit: parseInt(e.target.value) } as Account)}
                />
            </div>
            {/*
            <div className='field'>
                <label className='label'>Send warning to (email addresses) &nbsp;
                    <button onClick={() => {
                        const warningEmails = acc.event_limit_notify || [];
                        warningEmails.push("");
                        setAccount({ ...acc, event_limit_notify: warningEmails } as Account) 
                    }}><IconPlus /></button> </label>
                {(acc.event_limit_notify || []).map((email, idx) => (
                    <input
                        className='input'
                        type='email'
                        value={email}
                        onChange={(e) => { 
                            const warningEmails = acc.event_limit_notify || [];
                            warningEmails[idx] = e.target.value;
                            setAccount({ ...acc, event_limit_notify: warningEmails } as Account) 
                        }}
                    />)
                )}
            </div>
            */}
            <button
                className='button is-primary'
                onClick={() => {
                    onSave(acc);
                }}
            ><IconSave />&nbsp; Save</button>
        </div>
    );
}

const AccountTabs: FC = () => {
    const currentLocation = useLocation();
    return (
        <>
        <div className='title'>Account settings</div>
        <div className='tabs'>
            <ul>
                <li className={currentLocation.pathname === "/account" ? 'is-active' : ''}><Link to='/account'>Account</Link></li>
                <li className={currentLocation.pathname === "/account/users" ? 'is-active' : ''}><Link to='/account/users'>Users</Link></li>
                <li className={currentLocation.pathname === "/account/limits" ? 'is-active' : ''}><Link to='/account/limits'>Limits</Link></li>
            </ul>
        </div>
        </>
    )
};

export { AccountContactForm, AccountLimitForm, AccountTabs };
